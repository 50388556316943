import React from "react";
import "../assets/styles/app.css";
import { Link } from "react-router-dom";
import Menu from "./Menu";

const Header = () => {
  return (
    <>
      <div className="app-header white box-shadow">
        <div className="navbar navbar-toggleable-sm flex-row align-items-center">
          <Link
            data-toggle="modal"
            data-target="#aside"
            className="hidden-lg-up mr-3"
          >
            <i className="material-icons">&#xe5d2;</i>
          </Link>

          <div
            className="mb-0 h5 no-wrap"
            ng-bind="$state.current.data.title"
            id="pageTitle"
          ></div>

          <div className="collapse navbar-collapse" id="collapse">
            <ul className="nav navbar-nav mr-auto">
              <li className="nav-item dropdown">
                <Link className="nav-link" href="true" data-toggle="dropdown">
                  <span id="nav-top-header">
                    👋 Welcome to Your MyLand IT Dashboard! 🌐 We’re excited to
                    have you here! This dashboard is your one-stop shop for all
                    your IT needs at MyLand. Whether you’re tracking
                    appointments, accessing resources, or getting support,
                    everything you need is right at your fingertips. Explore the
                    tools, stay updated, and let us know how we can assist you.
                  </span>
                </Link>
              </li>
            </ul>

            <div ui-include="'./views/blocks/navbar.form.html'"></div>
          </div>

          <ul className="nav navbar-nav ml-auto flex-row">
            <li className="nav-item dropdown pos-stc-xs"></li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link p-0 clear"
                to="/profile"
                data-toggle="dropdown"
              >
                {/* <span style={{ whiteSpace: "pre" }}>Last Admin</span> */}
                {/* <span className="avatar w-32">
                  <img src="./assets/images/dummy-image.jpg" alt="..." />
                  <i className="on b-white bottom"></i>
                </span> */}
              </Link>
              <div ui-include="'./views/blocks/dropdown.user.html'"></div>
            </li>
            <li className="nav-item hidden-md-up">
              <Link
                className="nav-link pl-2"
                data-toggle="collapse"
                data-target="#collapse"
              >
                <i className="material-icons">&#xe5d4;</i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Mobile Menu Modal */}
      <div
        className="modal fade"
        id="aside"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="asideLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="asideLabel">
                Menu
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Menu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
