import React, { useState } from "react";
import Menu from "../Menu";
import "./navbar.css";
import "../../../node_modules/jquery/dist/jquery";
import "../../../node_modules/bootstrap/dist/js/bootstrap";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../assets/bootstrap/dist/css/bootstrap.css";
import "../../assets/styles/app.css";
import "../../assets/styles/font.css";
import "../../assets/animate.css/animate.min.css";
import "../../assets/glyphicons/glyphicons.css";
import "../../assets/font-awesome/css/font-awesome.min.css";
import "../../assets/material-design-icons/material-design-icons.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div
        id="aside"
        className={`app-aside modal nav-dropdown ${isMenuOpen ? "show" : ""}`}
        onClick={toggleMenu}
      >
        <div className="left navside dark dk" data-layout="column">
          <Menu />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
