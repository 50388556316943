import React from "react";
import MainRouter from "./router/MainRouter";

const Main = () => {
  return (
    <>
      <MainRouter />
    </>
  );
};

export default Main;
