import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import laimg1 from "../assets/images/card_img.png";
import laimg2 from "../assets/images/2 (2).png";
import laimg3 from "../assets/images/3.png";
import laimg4 from "../assets/images/4.png";
import laimg5 from "../assets/images/5.png";
import laimg6 from "../assets/images/6.png";
import laimg7 from "../assets/images/7.png";
import laimg8 from "../assets/images/08.png";
import laimg9 from "../assets/images/9.png";
import laimg10 from "../assets/images/10.png";
import laimg11 from "../assets/images/11.png";
import laimg12 from "../assets/images/12.png";
import laimg13 from "../assets/images/13.png";
import laimg14 from "../assets/images/14.png";



const LAExclusive = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <div ui-view class="app-body" id="view">
          <div class="la_exclusive_container">
            <div class="la_exclusive_header">
              <div class="exclusive_left"></div>
              <div class="exclusive_title">
                Last Admin Exclusives and Investing in our partners
              </div>
            </div>
            <div class="la_exclusive_body">
              <div class="la_exclusive_body_title">
                Last Admins established Back end tools
              </div>
              <div class="exclusive_card_wrapper">
                <div class="la_exclusive_cards">
                  <div class="exclusive_card_top">
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg1} alt="" />
                      </div>
                      <div class="la_exclusive_card_body" id="font01">
                        <b>1.</b>&nbsp; Last Admins Industry leading ticketing
                        and project management system - Saves MyLand Money Jira
                        Online server and agent licensing already established
                        and shared access with MyLand
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg2} alt="" />
                      </div>
                      <div class="la_exclusive_card_body">
                        <b>2.</b>&nbsp; Windows 10 and Windows 11 Images created
                        abd UpToDate - Saves Time for setup Created specifically
                        for MyLand environment with all software, printers, and
                        Wi-Fi installed and system updated and ready for
                        development on any Windows based system.Last Admins
                        Windows Image Server to use with Last Admins Images -
                        Saves MyLand Money.
                      </div>
                    </div>
                  </div>
                  <div class="exclusive_card_middle">
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg3} alt="" />
                      </div>
                      <div class="la_exclusive_card_body" id="font03">
                        <b>3.</b>&nbsp; Last Admin Image Server deployed onsite
                        at MyLand. Handeling out premade images to any window
                        device in a matter of minutes. No cost for the hardwate
                        or licensing.
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg4} alt="" />
                      </div>
                      <div class="la_exclusive_card_body">
                        <b>4.</b>&nbsp; Last Admin cloud based Remote Control
                        server established Saves MyLand Money Remote Server, and
                        client software are an espensive sost, but last admin
                        saves by sharing our access with you.
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg5} alt="" />
                      </div>
                      <div class="la_exclusive_card_body" id="font02">
                        <b>5.</b>&nbsp; System Spare Systems. For immediate
                        help.Saves MyLand Money Full systems imaged and ready to
                        use save MyLand money by supplying at no cost.
                      </div>
                    </div>
                  </div>
                  <div class="exclusive_card_bottom">
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg6} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "25px" }}
                      >
                        <b>6.</b>&nbsp; Last Admins Onboarding system
                        established and shared with MyLand Saves time
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg7} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "30px" }}
                      >
                        <b>7.</b>&nbsp; Last Admin L3 Pricing MCA - Saves MyLand
                        Money
                      </div>
                    </div>
                  </div>
                </div>
                <div class="la_exclusive_cards">
                  <div class="exclusive_card_top">
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg8} alt="" />
                      </div>
                      <div class="la_exclusive_card_body">
                        <b>8.</b>&nbsp; Last Amdin Provide its own furniture for
                        on sight support - Saves MyLand for on sight support -
                        Saves MyLand Money. We don't ask for any purchase need
                        to support your systems. You are already paying a
                        service fee we don't place any burden on you.
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg9} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "19px" }}
                      >
                        <b>9.</b>&nbsp; Last Admin website portal - Saves Myland
                        Time Going the extra mile to give you another tools to
                        save time and bridge the communication gap of all things
                        IT
                      </div>
                    </div>
                  </div>
                  <div class="exclusive_card_middle">
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg10} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "24px" }}
                      >
                        <b>10.</b>&nbsp; System User Training videos - Saves
                        MyLand Money Provided at no cost to MyLand
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg11} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "21px" }}
                      >
                        <b>11.</b>&nbsp; ALtera interim support software - Saves
                        MyLand Money Support as fruth removes support
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg12} alt="" />
                      </div>
                      <div class="la_exclusive_card_body">
                        <b>12.</b>&nbsp; Last Admin Support systems at MyLand -
                        saves MayLand Money The Box System is a network
                        dashboard system that includes a PC and monitor to give
                        real time access to the firewall
                      </div>
                    </div>
                  </div>
                  <div class="exclusive_card_bottom">
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg13} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "19px" }}
                      >
                        <b>13.</b>&nbsp; Last Admin SUpport systems at MyLand -
                        saves MyLand Money La-PC is on-site system that allow
                        testing and insight to MyLands environment remotely
                      </div>
                    </div>
                    <div class="la_exclusive_card1">
                      <div class="la_exclusive_card_image">
                        <img src={laimg14} alt="" />
                      </div>
                      <div
                        class="la_exclusive_card_body"
                        style={{ fontSize: "20px" }}
                      >
                        <b>14.</b>&nbsp; Last Admin Support work area setup at
                        MyLand -saves MyLand Money Workbench and storage cabins
                        onsite to provide onsite service.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LAExclusive;
