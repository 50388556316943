import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Footer from "../Components/Footer";
import "../assets/styles/app.css";
import calendarimg from "../assets/images/calendar1.png";
import usertraingimg from "../assets/images/usertaining.png";
import currentproject_img from "../assets/images/projects.png";
import firewallimg from "../assets/images/Firewall.png";
import opticket from "../assets/images/Help desk.png";
import mailimg from "../assets/images/email.png";
import myLandit from "../assets/images/MyLand_Logo_Horiz_2Color_RGB.png";
import Header from "../Components/Header";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <div ui-view="true" className="app-body" id="view">
          <div className="first_block_summary">
            <div className="first_block_insider">
              <div className="first_block_header">
                <img src={myLandit} alt="" />
              </div>
            </div>
          </div>

          <div className="project_block_summary" id="project_summary">
            <div className="project_block_insider">
              <div className="project_block_cards">
              <div className="project_block_card_1">
                  <div className="first_block_header2">
                    <Link
                      to="https://lastadmin.atlassian.net/servicedesk/customer/portal/2/user/login?destination=portal%2F2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={opticket} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="project_block_card_1">
                  <div className="first_block_header2">
                    <Link to="mailto:michael@lastadmin.com">
                      <img src={mailimg} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="project_block_card_1">
                  <div className="first_block_header2">
                    <Link to="/calender">
                      <img src={calendarimg} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="project_block_card_1">
                  <div className="first_block_header2">
                    <Link to="/userTraining">
                      <img src={usertraingimg} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="project_block_card_1">
                  <div className="first_block_header2">
                    <Link to="/allprojects">
                      <img src={currentproject_img} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="project_block_card_1">
                  <div className="first_block_header2">
                    <Link
                      to="https://account.ui.com/login?redirect=https%3A%2F%2Funifi.ui.com%2Fconsoles%2FD8B370873EE60000000007A3B312000000000805652A0000000064C2723C%3A482279805%2Fnetwork%2Fdefault%2Fdashboard"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={firewallimg} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
