import React from "react";
import "../assets/styles/app.css";
import logoImage from "../assets/images/lastadmin_logo-removebg-preview.png";

const Footer = () => {
  return (
    <>
      <div className="app-footer">
        <div className="footer_insider">
          <div className="footer-right">
            <img src={logoImage} alt="logo" />
          </div>
          <div className="footer_left">
            <div className="footer_text">
              Your Network, Azure, and Support Desk partner.
            </div>
            <div className="footer_contact">
              <div className="footer_contact_number">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                  />
                </svg>
                <a href="tel:4807141111">480-714-1111</a>
              </div>
              <div className="footer_contact_main">
                <a
                  href="https://www.lastadmin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.LastAdmin.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
