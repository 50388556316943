import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../assets/styles/app.css";

const Calendar = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <div ui-view class="app-body" id="view">
          <div class="videos_main">
            <div class="videos_insider">
              <div class="videos_main">
                <iframe
                  src="https://outlook.office365.com/calendar/published/260bcf61c3d341b0aba01f960d0c9a31@lastadmin.com/70d0a026805343719853fc8f04e97429687937616632936432/calendar.html"
                  style={{ border: "none" }}
                  width="100%"
                  title="Calendar"
                  height="500"
                  frameBorder="0"
                  scrolling="no"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Calendar;
