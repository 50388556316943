import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const AllProject = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <div ui-view class="app-body" id="view">
          <div class="videos_main">
            <div class="videos_insider">
              {/* <iframe 
              src="https://onedrive.live.com/embed?resid=383772C4E997F5D1%2135880&authkey=!ACfvbBRtuHqXJQ4" 
              width="100%" 
              height="600" 
              frameborder="0" 
              scrolling="no" 
              title="OneDrive Embed"
            ></iframe> */}

              <iframe
                src="https://lastadmin-my.sharepoint.com/:w:/p/michael/ETQajdgm9-1DqN2QUR81d-YBigYluphtsCPOVvGcazw3bw?rtime=0KxIswS83Eg&amp;action=embedview"
                width="476px"
                height="288px"
                frameborder="0"
              >
                This is an embedded{" "}
                <a target="_blank" href="https://office.com">
                  Microsoft Office
                </a>{" "}
                document, powered by{" "}
                <a target="_blank" href="https://office.com/webapps">
                  Office
                </a>
                .
              </iframe>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllProject;
