import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CommingSoon from "./CommingSoon";

const SystemTraining = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <CommingSoon />
        <Footer />
      </div>
    </>
  );
};

export default SystemTraining;
