import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const UserTraining = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <div ui-view class="app-body" id="view">
          <div class="videos_main">
            <div class="videos_insider">
              <div class="videos_main">
                <iframe
                  width="100%"
                  height="515"
                  src="https://www.youtube.com/embed/TOZfzo0_QzI?si=BefRO7jY6TBlabsD&autoplay=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="videos-primary-wrapper">
                <div class="header-video">Training Videos</div>
                <div class="videos-subsection">
                  <div class="sub1">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/nsfpSiqdWTE?si=bgAjofoLux9IAgoH"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="sub2">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/cm67pz7KEcQ?si=T-xddOxFgYBHNFX2"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="sub2">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/OkdlhEvLPYU?si=R9gQOb6Ao8F4xoOG"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>

              <div class="videos_with_headers">
                <div class="header-video">Last Admin Videos</div>
                <div class="videos_with_headers_insider">
                  <div class="sub3">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/iEYxOIzHU9g?si=MX5a7qWsTmMLky0X"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="sub3">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/rEzwjOlt3FI?si=4Zwo0KnNfY91UgKT"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="sub3">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/vmDx2QGEj3k?si=gMLZslG6ZPFJI2ko"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="sub3">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/I9J2eWc3pxs?si=fQ-QV7u6UlOx5Ju3"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UserTraining;
