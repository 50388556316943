import React from "react";
import { Link } from "react-router-dom";

const CommingSoon = () => {
  return (
    <>
      <div class="app-body amber bg-auto w-full">
        <div class="text-center pos-rlt p-y-md">
          <h1 class="text-shadow m-0 text-white text-4x">
            <span class="text-2x font-bold block m-t-lg">404</span>
          </h1>
          <h2 class="h1 m-y-lg text-black">OOPS!</h2>
          <p class="h5 m-y-lg text-u-c font-bold text-black">
            Sorry! the page you are looking for doesn't exist.
          </p>
          <Link ui-sref="app.dashboard" class="md-btn amber-700 md-raised p-x-md" to='/'>
            <span class="text-white">Go to the home page</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CommingSoon;
