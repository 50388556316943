import React from "react";
import Navbar from "../Components/navbar/Navbar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <>
      <Navbar />
      <div id="content" className="app-content box-shadow-z0" role="main">
        <Header />
        <div class="container">
          <div class="profile-info">
            {/* <img src="profile-picture.jpg" alt="Profile Picture" /> */}
            <h2>Michael Jage</h2>
            <p>CIO, Last Admin</p>
            <p>Arizona, USA</p>
          </div>

          <div class="profile-details">
            <h3>About Me</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit
              amet velit vel mauris lacinia venenatis ut vitae purus. Nullam et
              dolor vitae felis lacinia volutpat. Sed tincidunt, nunc nec
              condimentum gravida, mauris enim consequat justo, et accumsan
              risus nisi a nisl.
            </p>

            <h3>Contact Information</h3>
            <p>Email: Michael@lastadmin.com</p>
            {/* <p>Phone: +1 234 567 890</p>  */}

            <h3>Social Media</h3>
            <p>
              Twitter:{" "}
              <Link to="#" target="_blank">
                @michael
              </Link>
            </p>
            <p>
              LinkedIn:{" "}
              <Link to="#" target="_blank">
                Michael Jage
              </Link>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
