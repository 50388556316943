import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Calendar from "../pages/Calendar";
import AllProject from "../pages/AllProject";
import UserTraining from "../pages/UserTraining";
import SystemTraining from "../pages/SystemTraining";
import MeetTheTeam from "../pages/MeetTheTeam";
import LAExclusive from "../pages/LAExclusive";
import Profile from "../pages/Profile";

const MainRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/allprojects" element={<AllProject />} />
        <Route path="/userTraining" element={<UserTraining />} />
        <Route path="/systemTraining" element={<SystemTraining />} />
        <Route path="/meettheteam" element={<MeetTheTeam />} />
        <Route path="/laexclusive" element={<LAExclusive />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;
